import styled, { keyframes } from 'styled-components';
import { overlay } from '../../images';

export const camSize = {
	w: 228,
	h: 305,
	mw: 205,
	mh: 275,
};

export const Vignette = styled.div`
	&::before {
		position: absolute;
		content: '';
		background-image: url(${overlay});
		background-size: 100%;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
`;

export const BoldText = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
`;

export const Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
`;

export const width = keyframes`
    from {
        width: 0;
	}

	to {
		width: ${({ width }) => width}px;
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
`;

export const Process = styled.div`
	width: ${camSize.w}px;
	height: 10%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-botton: 5px;
`;

export const Line = styled.div`
	width: ${({ width }) => width}px;
	height: 3px;
	background-color: #2e6eff;
	animation: ${width} 0.1s ease-in-out;
`;

export const CamContainer = styled(Vignette)`
	width: ${camSize.w}px;
	height: ${camSize.h}px;
	display: block;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;

	@media screen and (max-width: 414px) {
		width: ${camSize.mw}px;
		height: ${camSize.mh}px;
		margin-bottom: 20px;
	}
`;

export const Result = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;
