import React, { useRef, useCallback, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import { delay, arrAvg } from './helpers';
import { camSize, Text, BoldText, Wrapper, Process, Result, Line, CamContainer } from './style';
import { Button, Spinner } from '../../components';
import { bCamera, bReload, sample } from '../../images';

const nPhotos = 4;
const progressParts = camSize.w / nPhotos;

const FaceApi = () => {
	const webcamRef = useRef(null);
	const [detec, setDetec] = useState();
	const [process, setProcess] = useState([]);
	const [modelsReady, setModelsReady] = useState(false);
	const [perf, setPerf] = useState();
	const [verifying, setVerifying] = useState(false);

	const preloadFaceapi = async image => {
		const base = new Image();
		base.src = image;
		await faceapi
			.detectSingleFace(base, new faceapi.TinyFaceDetectorOptions())
			.withAgeAndGender()
			.run();
	};

	useEffect(() => {
		const fetchModels = async () => {
			try {
				await faceapi.nets.ageGenderNet.loadFromUri('/models');
				await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
				await preloadFaceapi(sample);
				setModelsReady(true);
			} catch (err) {
				console.log(err);
			}
		};
		fetchModels();
	}, []);

	const processImage = useCallback(async () => {
		setDetec();
		setProcess([]);
		setVerifying(true);
		let arr = [];
		let i = 0;
		const t0 = performance.now();
		while (arr.length < nPhotos) {
			i += 1;
			if (i === 20) {
				break;
			}
			await delay(100);
			const detect = await faceapi
				.detectSingleFace(webcamRef.current.video, new faceapi.TinyFaceDetectorOptions())
				.withAgeAndGender();
			console.log(detect);
			arr.push(detect);
			arr = arr.filter(Boolean);
			setProcess(arr);
		}
		const t1 = performance.now();

		const delta = Math.round(((t1 - t0) / 1000) * 10) / 10;
		setPerf(delta);

		if (arr.length) {
			arr = arrAvg(arr.map(({ age }) => age));
			setDetec(arr);
		}
		setVerifying(false);
	}, [webcamRef]);

	return (
		<Wrapper>
			<Process>
				{detec ? (
					<Result>
						<BoldText>Recognition Finished</BoldText>
						<Text>in {perf} sec</Text>
					</Result>
				) : (
					process.map(el => <Line width={progressParts} key={el.age} />)
				)}
			</Process>
			<CamContainer>
				<Webcam
					screenshotFormat={'image/jpeg'}
					audio={false}
					ref={webcamRef}
					videoConstraints={{ facingMode: 'user' }}
					screenshotQuality={1}
					minScreenshotWidth={720}
					imageSmoothing={false}
					height={305}
					style={{ transform: 'scaleX(-1)' }}
				/>
			</CamContainer>
			{modelsReady ? (
				verifying ? (
					<>
						<Text>Processing image</Text>
						<Spinner />
					</>
				) : detec ? (
					<>
						<Text>Estimated age</Text>
						<BoldText>{detec} years</BoldText>
						<Button w={'170px'} removeBorder icon={bReload} click={processImage}>
							Retry
						</Button>
					</>
				) : (
					<Button click={processImage} icon={bCamera} w={'160px'}>
						Proceed
					</Button>
				)
			) : (
				<Text>Please wait until the required module is loaded..</Text>
			)}
		</Wrapper>
	);
};

export default FaceApi;
