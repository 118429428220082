import React, { useRef, useCallback } from 'react';
import { bCamera } from '../../images';
import { Button } from '../../components';
import { Vignette, Message, camSize } from './style';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import { NEXT_STEP, SET_IMAGE } from './reducer';

const TakePhoto = ({ dispatch, state, ...props }) => {
	const webcamRef = useRef(null);

	const b64toBlob = async b64Imge => {
		const response = await fetch(b64Imge);
		const blob = await response.blob();
		return blob;
	};

	const videoConstraints = {
		facingMode: 'user',
		width: camSize.w,
		height: camSize.h,
	};

	const takePhoto = useCallback(async () => {
		const img = webcamRef.current.getScreenshot();
		const blob = await b64toBlob(img);
		const localImg = window.URL.createObjectURL(blob);
		dispatch({ type: SET_IMAGE, img: localImg, b64Img: img });
		dispatch({ type: NEXT_STEP });
	}, [webcamRef, dispatch]);

	return (
		<>
			<Message>Please take a clear picture of yourself so that we can verify your age</Message>
			<CamContainer>
				<Webcam
					screenshotFormat={'image/jpeg'}
					audio={false}
					ref={webcamRef}
					videoConstraints={props.isMobile ? { facingMode: 'user' } : videoConstraints}
					screenshotQuality={1}
					minScreenshotWidth={720}
					imageSmoothing={false}
					height={props.isMobile ? camSize.mh : camSize.h}
					style={{ transform: 'scaleX(-1)' }}
				/>
			</CamContainer>
			<Button click={takePhoto} removeBorder icon={bCamera} w={'160px'}>
				Take Photo
			</Button>
		</>
	);
};

export default TakePhoto;

const CamContainer = styled(Vignette)`
	width: ${camSize.w}px;
	height: ${camSize.h}px;
	display: block;
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;

	@media screen and (max-width: 414px) {
		width: ${camSize.mw}px;
		height: ${camSize.mh}px;
		margin-bottom: 20px;
	}
`;
